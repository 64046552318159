<template>
  <v-row>
     <Confirmacion
      :pregunta="consultaDialog"
      :consulta="preguntaConsulta"
      :loading="loadingPregunta"
      @si="confirmacionAceptada"
      @no="consultaDialog=false"
      v-if="actions"
    />
    <v-col cols="12">
      <v-row v-if="add">
        <v-col cols="12">
          <NuevaExperiencia 
            :areas="areas" 
            :conDatos="datosCreacion" 
            @creado="creado"
            :urlAdd="urlAdd"
            :globalAdd="globalAdd"
          />
        </v-col>
      </v-row>
       <small v-show="select">Experiencia Selecccionada : {{selectedIds.length}}</small>
      <v-data-table
        v-model="selected"
        :headers="computedHeaders"
        :items="desserts"
        item-key="id"
        :server-items-length="totalDesserts"
        :hide-default-footer="hiddenFoter"
        noDataText="No hay ninguna Experiencia Registrada para el Abogado"
        :mobile-breakpoint="100"
        calculate-widths
        :show-select="select"
        :loading="loadingTable"
        loading-text="Cargando Datos"
      >
        <template v-slot:item.acciones="{item}">
          <v-tooltip bottom v-if="item.verificacion==null">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" icon v-bind="attrs" v-on="on" @click="eliminarExperiencia(item)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <span>Eiminar registro</span>
          </v-tooltip>
        </template>
        <template v-slot:item.verificacion="{ item }" class="hidden-xs-only">
          <v-chip
            small
            :color="colorEstado(item.verificacion)"
            text-color="white"
          >{{textoEstado(item.verificacion)}}</v-chip>
        </template>
        <template v-slot:item.areaExperiencia="{ item }" class="hidden-xs-only">
          {{getNombreParametro(item,'area_experiencia')}}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import Mabogado from "@/mixins/abogado"
import mixinParametro from "@/mixins/parametro"
import Confirmacion from "@/common/util/Confirmacion";
export default {
  props:{
    select:{
      type:Boolean,
      default:false
    },
    actions:{
      type:Boolean,
      default:true
    },
    experiencias:{
      type: Array,
      default:()=>{
        return []
      }
    },
    general:{
      type:Boolean,
      default:true
    },
    add:{
      type:Boolean,
      default:true
    }
    ,datosCreacion:{
      type:Boolean,
      default:false
    },
    areas:{
      type: Array,
      default:()=>{
        return []
      }
    },
    urlAdd:{
      type: String,
      default: 'abogado-ciudadano/experiencia'
    },
    globalAdd:{
      type: Boolean,
      default: true
    }
  },
  components: { 
    'NuevaExperiencia':()=>import("@/components/NuevaExperiencia"),
    Confirmacion 
  },
  mixins: [Mabogado, mixinParametro],
  data() {
    return {
      consultaDialog: false,
      preguntaConsulta: "",
      tipoConsulta: null,
      loadingPregunta:false,
      experienciaSeleccionada:{},
      selected: [],
      headers: [
        {
          text: "Acciones",
          align: "left",
          value: "acciones",
          sortable: false,
          width:'70px'
        },
        {
          text: "Cargo",
          align: "left",
          value: "cargo",
          sortable: false,
          width:'200px'
        },
        {
          text: "Estado",
          align: "left",
          value: "verificacion",
          sortable: false,
          width:'200px'
        },
        {
          text: "Institución",
          align: "left",
          value: "institucion",
          sortable: false,
          width:'200px'
        },
        {
          text: "Area Experiencia",
          align: "left",
          value: "areaExperiencia",
          sortable: false,
          width:'200px'
        }
      ],
      hiddenFoter: true
    };
  },
  async created(){
    if (this.general && this.$store.state.abogado.experiencia.length === 0) {
      await this.getExperienciaAbogado();
    }
  },
  computed: {
    desserts() {
      return this.general?this.$store.state.abogado.experiencia:this.experiencias;
    },
    totalDesserts() {
      if (this.desserts.length > 8) {
        this.hiddenFoter = false;
      }
      return this.desserts.length;
    },
    selectedIds() {
      var ids = [];
      if (this.selected.length > 0) {
        this.selected.forEach(item => {
          ids.push(item.id);
        });
      }
      this.$emit("datosSeleccionados", ids);
      return ids;
    },
    computedHeaders(){
       if(!this.actions){
         let i = this.headers.map(item => item.text).indexOf('Acciones')
            this.headers.splice(i,1);
      }
      return this.headers;
    }
  },
  methods: {
    colorEstado(data) {
      if (data === null) {
        return "primary";
      } else if (data) {
        return "success";
      }
      return "error";
    },
    textoEstado(data) {
      if (data === null) {
        return "NUEVO";
      } else if (data) {
        return "VERIFICADO";
      }
      return "RECHAZADO";
    },
    eliminarExperiencia(item) {
      Object.assign(this.experienciaSeleccionada, item);
      this.preguntaConsulta = `¿Estas seguro de eliminar la experiencia laboral con cargo <strong>" ${this.experienciaSeleccionada.cargo} "</strong>?. esta accion no se podra revertir`;
      this.consultaDialog = true;
    },
    confirmacionAceptada() {
      this.loadingPregunta = true;
      this.deleteExperiencia(this.experienciaSeleccionada.id)
    },
    creado(data){
        if(this.add && this.select){
          if(!this.selected.some(e=>e.id===data.id)){
            this.selected.push(data)
          }
        }else if(this.add && !this.globalAdd){
          this.$emit('nuevo',data)
        }
    }
  }
};
</script>

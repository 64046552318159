export default {
    methods:{
        getNombreParametro(item,propiedad){
            if(typeof item[propiedad] === 'object' ){
              return item[propiedad].nombre
            }else if(typeof item[this.snakeToCamel(propiedad)] === 'object'){
              return item[this.snakeToCamel(propiedad)].nombre
            }else if(item[this.snakeToCamel(propiedad)]!==null){
              return item[this.snakeToCamel(propiedad)]
            }else{
              return item
            }
        },
        snakeToCamel(str){
          return str.replace(
            /([-_][a-z])/g,
            (group) => group.toUpperCase()
              .replace('-', '')
              .replace('_', '')
          )
        }
    }
}